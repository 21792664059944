<template>
  <div :class="containerClass" @click="onWrapperClick">
    <template v-if="auth.isLoggedIn">
      <div
        class="layout-topbar surface-ground shadow-none scalein animation-duration-500"
      >
        <div
          class="layout-topbar-logo flex-grow-none outline-none"
          v-ripple
          @click="app.navigateTo(`/`)"
        >
          <img src="/images/logo.png" alt="logo" class="my-5 border-round" />
          <span class="text-600 txt">{{ $appName }}</span>
        </div>
        <div class="layout-toggle-menu">
          <div class="w-full p-6 ml-8"></div>
          <Button
            class="hidden md:flex layout-topbar-button text-600 p-button-text pl-4 pr-4"
            @click="onMenuToggle"
          >
            <i class="pi pi-bars"></i>
          </Button>
           <router-link to="/account" class="flex-grow-1">
<Avatar shape="circle" icon="pi pi-user" class="md:hidden bg-black-100 text-black-500 p-4"  
 />
 </router-link>
          <Drawer>
            <DrawerTrigger as-child>
              <Button
                class="hidden layout-topbar-button text-600 p-button-text pl-4 pr-4"
              >
                <i class="pi pi-bars"></i>
              </Button>
            </DrawerTrigger>
            <DrawerContent>
              <div class="mx-auto w-full max-w-sm">
                <DrawerHeader>
                  <DrawerTitle>Quick actions</DrawerTitle>
                </DrawerHeader>
                <div class="p-1 pb-0">
                  <div class="h-[320px]">
                    <AppMenuMobile
                      :model="navbarSideLeftItems"
                      @menuitem-click="onMenuItemClick"
                    />
                  </div>
                </div>
              </div>
            </DrawerContent>
          </Drawer>
        </div>

        <div class="layout-topbar-menu flex-grow-1 justify-content-between">
          <div class="flex">
            <template
              v-for="(item, index) of navbarTopLeftItems"
              :key="`navtopleftmenu-${index}`"
            >
              <router-link :to="item.to">
                <Button
                  :label="item.label"
                  :icon="item.icon"
                  class="p-button-text text-600 page-button"
                />
              </router-link>
            </template>
          </div>
        </div>
      </div>
      <div
        class="layout-sidebar sm:fadeinleft shadow-right-4 surface-border overflow-auto"
        @click="onSidebarClick"
      >
        <div class="text- p-2 pl-5">
          <!--custom-->

          <!--custom end-->
        </div>

        <AppMenu
          class=""
          :model="navbarSideLeftItems"
          @menuitem-click="onMenuItemClick"
        />
        <div class="flex items-center justify-between mt-6 p-2">
          <a href="#" class="flex items-center gap-x-2">
            <router-link to="/account" class="flex-grow-1">
              <span class="ml-2 text-600">{{ auth.user.full_name }}</span>
            </router-link>
          </a>

          <a
            @click="startLogout()"
            class="text-gray-500 transition-colors duration-200 rotate-180 dark:text-gray-400 rtl:rotate-0 hover:text-blue-500 dark:hover:text-blue-400"
          >
            <ion-icon name="log-out-outline" size="small"></ion-icon>
          </a>
        </div>
        <div
          class="absolute bottom-0 left-0 right-0 m-2 align-items-center"
        ></div>
      </div>
      <div class="layout-main-container">
        <div class="layout-main scalein animation-duration-500 mb-5">
          <router-view class="layout-main scalein animation-duration-300" />
           <PWAInstallPrompt />

           
   
        </div>
          <MobileBottomBar   :activeColor="'#3f3f46'" 
      :inactiveColor="'#3f3f46'" 
      :excludeRoutes="excludeRoutes"  :tabs="tabs"
    />
      </div>
    </template>
    <router-view class="layout-main scalein animation-duration-300" v-else />
  
    <!-- App right drawer -->
    <Sidebar
      :showCloseIcon="false"
      position="right"
      v-model:visible="rightDrawer.showDrawer"
      :style="{ width: rightDrawer.width }"
    >
      <component
        v-if="rightDrawer.showDrawer"
        is-sub-page
        :is="drawerComponentFile"
        :api-path="rightDrawer.pageUrl"
        :page-data="rightDrawer.pageData"
      />
    </Sidebar>

    <transition name="layout-mask">
      <div
        class="layout-mask p-component-overlay"
        v-if="mobileMenuActive"
      ></div>
    </transition>

    <!-- Page display dialog -->
    <Dialog
      class="card py-4 px-0"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      :style="{ width: pageDialog.width, maxWidth: pageDialog.maxWidth }"
      :modal="!pageDialog.seamless"
      :maximized="pageDialog.maximized"
      :dismissableMask="!pageDialog.persistent"
      :position="pageDialog.position"
      v-model:visible="pageDialog.showDialog"
      :showHeader="false"
    >
      <Button
        v-if="pageDialog.closeBtn"
        @click="pageDialog.showDialog = false"
        style="position: absolute; right: 10px; top: 10px;"
        icon="pi pi-times"
        class="p-button-rounded p-button-text p-button-plain"
      />

      <component
        v-if="pageDialog.showDialog"
        is-sub-page
        :is="dialogComponentFile"
        :api-path="pageDialog.pageUrl"
        :page-data="pageDialog.pageData"
      />
    </Dialog>

    <!-- image preview dialog-->
    <Dialog
      class="card p-0"
      header="..."
      :showHeader="true"
      :breakpoints="{ '960px': '40vw', '640px': '95vw' }"
      style="width: auto;"
      v-model:visible="imageDialog.showDialog"
      dismissableMask
      modal
    >
      <div class="text-center">
        <Carousel
          v-if="imageDialog.images.length > 1"
          :value="imageDialog.images"
          :circular="false"
          :page="imageDialog.currentSlide"
        >
          <template #item="{data}">
            <div class="text-center">
              <NiceImage
                style="max-width: 100%; max-height: 70vh;"
                :src="data"
              />
            </div>
          </template>
        </Carousel>
        <NiceImage
          v-else
          style="max-width: 100%; max-height: 70vh;"
          :src="imageDialog.images[0]"
        />
      </div>
    </Dialog>

    <!-- request error dialog -->
    <Dialog
    v-model:visible="errorDialog"
    :modal="true"
    :breakpoints="{
      '1200px': '40vw',
      '992px': '50vw',
      '768px': '70vw',
      '576px': '90vw'
    }"
    :style="{
      width: '35vw',
      maxWidth: '550px',
      minWidth: '300px'
    }"
    position="top"
    class="w-full p-0 border-1 surface-border rounded-xl"
  >
    <template #header>
      <div class="flex align-items-center gap-3 w-full ">
        <Avatar
          icon="pi pi-exclamation-circle"
          size="large"
          class="bg-red-100 p-3 text-red-600"
          :style="{ width: '3rem', height: '3rem' }"
        />
        <div class="flex flex-column">
          <span class="text-xl font-bold text-red-700 line-height-3">Error</span>
          <span class="text-red-600 font-medium">Unable to complete request</span>
        </div>
      </div>
    </template>

    
    
  </Dialog>
    <!-- app confirm page dialog -->
    <ConfirmDialog></ConfirmDialog>

    <!-- app flash message toast -->
    <Toast position="top-right" class="" />

    <!-- app fullscreen loading dialog -->
    <Dialog
      class="card m-0"
      :showHeader="false"
      modal
      v-model:visible="fullScreenLoading"
      :breakpoints="{ '960px': '50vw', '640px': '95vw' }"
      style="width: 20vw;"
      position="center"
    >
      <ProgressBar mode="indeterminate" style="height: 6px;"></ProgressBar>
      <div class="mt-2 text-center text-500">{{ fullScreenLoadingMsg }}</div>
    </Dialog>
  </div>
</template>
<script setup>
import { defineAsyncComponent, ref, toRefs, computed } from "vue";
import AppMenuMobile from "src/components/AppMenuMobile.vue";
import AppMenu from "src/components/AppMenu.vue";
import { useApp } from "src/composables/app";
import { useAuth } from "src/composables/auth";
import { useRoute, useRouter } from "vue-router";

import { useAppStore } from "src/store/app";

const app = useApp();
const auth = useAuth();
const store = useAppStore();
//const route = useRoute();
//const router = useRouter();

const {
  pageDialog,
  imageDialog,
  rightDrawer,
  pageErrors,
  fullScreenLoading,
  fullScreenLoadingMsg,
} = toRefs(store);
const dialogComponentFile = computed(() => {
  if (pageDialog.value.showDialog && pageDialog.value.pageComponent) {
    return defineAsyncComponent(() =>
      import(`src/pages/${pageDialog.value.pageComponent}.vue`)
    );
  }
  return null;
});

const drawerComponentFile = computed(() => {
  if (rightDrawer.value.showDrawer && rightDrawer.value.pageComponent) {
    return defineAsyncComponent(() =>
      import(`src/pages/${rightDrawer.value.pageComponent}.vue`)
    );
  }
  return null;
});

const errorDialog = computed({
  get() {
    return pageErrors.value.length > 0;
  },
  set(newValue) {
    pageErrors.value = [];
  },
});
const layoutMode = ref("static");
const menuClick = ref(false);
const staticMenuInactive = ref(false);
const overlayMenuActive = ref(false);
const mobileMenuActive = ref(false);

const containerClass = computed(() => {
  if (!auth.isLoggedIn) {
    staticMenuInactive.value = true;
    mobileMenuActive.value = false;
  }

  return [
    "layout-wrapper",
    {
      "layout-overlay": layoutMode.value === "overlay",
      "layout-static": layoutMode.value === "static",
      "layout-static-sidebar-inactive":
        staticMenuInactive.value && layoutMode.value === "static",
      "layout-overlay-sidebar-active":
        overlayMenuActive.value && layoutMode.value === "overlay",
      "layout-mobile-sidebar-active": mobileMenuActive.value,
      "p-input-filled": false,
    },
  ];
});
const navbarSideLeftItems = app.menus.navbarSideLeft.filter((menu) =>
  auth.canView(menu.to)
);
const navbarTopLeftItems = app.menus.navbarTopLeft.filter((menu) =>
  auth.canView(menu.to)
);
const navbarTopRightItems = app.menus.navbarTopRight.filter((menu) =>
  auth.canView(menu.to)
);
function onMenuItemClick(event) {
  if (event.item && !event.item.items) {
    overlayMenuActive.value = false;
    mobileMenuActive.value = false;
  }
}

function onWrapperClick() {
  if (!menuClick.value) {
    overlayMenuActive.value = false;
    mobileMenuActive.value = false;
  }
  menuClick.value = false;
}

function onMenuToggle() {
  menuClick.value = true;
  if (app.isDesktop()) {
    if (layoutMode.value === "overlay") {
      if (mobileMenuActive.value === true) {
        overlayMenuActive.value = true;
      }
      overlayMenuActive.value = !overlayMenuActive.value;
      mobileMenuActive.value = false;
    } else if (layoutMode.value === "static") {
      staticMenuInactive.value = !staticMenuInactive.value;
    }
  } else {
    mobileMenuActive.value = !mobileMenuActive.value;
  }
}
const appBarActionMenu = [
  {
    to: "/account",
    label: "My Account",
    icon: "pi pi-user",
  },
  {
    label: "Logout",
    icon: "pi pi-window-minimize",
    command: (event) => {
      startLogout();
    },
  },
];

//tab bar deata
 const excludeRoutes = ['events/view', 'chama/view','channels/view','business/view'];
    const tabs = [
      { name: 'home', label: 'Home', inactiveIcon: 'home-outline',activeIcon: 'home', route: '/' },
        { name: 'Channels', label: 'Channels', inactiveIcon: 'wallet-outline',  activeIcon: 'wallet', route: '/channels' },
      { name: 'events', label: 'Events', inactiveIcon: 'calendar-outline', activeIcon: 'calendar', route: '/events' },
      { name: 'chama', label: 'Chama', inactiveIcon: 'people-outline', activeIcon: 'people', route: '/chama' },
      {name: 'business', label: 'Business', inactiveIcon: 'briefcase-outline', activeIcon: 'briefcase', route: '/business' },
      

    ]
function startLogout() {
  auth.logout();
  location.href = "/"; //reload page and navigated to index page
}
</script>
