<template>
  <div class="pwa-install-prompt">
    <!-- Install Dialog -->
<Dialog 
      v-if="showInstallPrompt" 
      v-model:visible="showInstallPrompt" 
      modal 
      :dismissableMask="true"
      :closeIcon="false"
      :closable="false"
      :style="{ width: '300px' }"
      class="install-dialog"
    >
      <div class="p-3">
        <div class="flex justify-content-center align-items-center mb-4">
          <span class="text-base font-medium text-center">Install App</span>
        </div>
        <div class="flex justify-content-center gap-2">
          <Button 
            label="Not Now" 
            @click="dismissAlert" 
            class="p-button-outlined p-button-sm"
          />
          <Button 
            label="Install" 
            icon="pi pi-download"
            @click="installPWA" 
            :loading="installing"
            class="p-button-primary p-button-sm"
          />
        </div>
      </div>
    </Dialog>

    <!-- Dismiss Info Dialog -->
    <Dialog 
      v-model:visible="showDismissMessage" 
      modal 
      :dismissableMask="false"
      :closeIcon="false"
      :closable="false"
      class="install-dialog"
    >
      <div class="p-fluid">
        <span class="text-lg block mb-3">
          To install the app later, use your browser's menu and select "Add to Home screen"
        </span>
        <div class="flex justify-content-end">
          <Button 
            label="Got it" 
            @click="closeDismissDialog" 
            class="p-button-primary"
          />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
export default {
  name: 'PWAInstallPrompt',
  
  data() {
    return {
      deferredPrompt: null,
      showInstallPrompt: false,
      showDismissMessage: false,
      installing: false,
    };
  },

  mounted() {
    if (!this.isAlreadyInstalled() && !this.isDismissed()) {
      this.setupInstallPrompt();
    }
  },

  methods: {
    isAlreadyInstalled() {
      return window.matchMedia('(display-mode: standalone)').matches;
    },

    isDismissed() {
      return localStorage.getItem('dismissInstallPrompt') === 'true';
    },

    setupInstallPrompt() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        this.deferredPrompt = e;
        this.showInstallPrompt = true;
      });

      window.addEventListener('appinstalled', () => {
        this.showInstallPrompt = false;
        this.$toast.add({
          severity: 'success',
          summary: 'Success',
          detail: 'App installed successfully!',
          life: 3000
        });
      });
    },

    async installPWA() {
      if (!this.deferredPrompt) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Installation not available',
          life: 3000
        });
        return;
      }

      try {
        this.installing = true;
        await this.deferredPrompt.prompt();
        const result = await this.deferredPrompt.userChoice;
        
        if (result.outcome === 'accepted') {
          this.$toast.add({
            severity: 'info',
            summary: 'Installing',
            detail: 'Installing application...',
            life: 2000
          });
        }
        
        this.deferredPrompt = null;
        this.showInstallPrompt = false;
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Installation failed. Please try again.',
          life: 3000
        });
      } finally {
        this.installing = false;
      }
    },

    dismissAlert() {
      this.showInstallPrompt = false;
      localStorage.setItem('dismissInstallPrompt', 'true');
      this.showDismissMessage = true;
    },

    closeDismissDialog() {
      this.showDismissMessage = false;
    }
  }
};
</script>

<style scoped>
.pwa-install-prompt {
  z-index: 1000;
}

.install-dialog {
  max-width: 400px;
}

.dismiss-dialog {
  max-width: 450px;
}

:deep(.p-dialog-content) {
  padding: 1.5rem;
}

:deep(.p-dialog-header) {
  display: none;
}
</style>