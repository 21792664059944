<template>
  <div class="bottom-nav border-top-1 mt-6 pl-2 pr-2 pb-2 surface-section" v-if="isMobile && !isExcludedRoute">
    <ul v-if="tabs.length > 1">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: activeTab === tab.name }"
      >
        <router-link 
          :style="getIconStyle(tab.name)"
          :to="tab.route" 
          @click.native="setActiveTab(tab.name)"
        >
          <div>
            <ion-icon :name="getIconName(tab)" :style="getIconStyle(tab.name)"></ion-icon>
          </div>
          <span class="font-normal text-500`0" :style="getIconStyle(tab.name)">{{ tab.label }}</span>
        </router-link>
      </li>
    </ul>
    <div v-else class="no-tabs-message">
      <p v-if="tabs.length === 0">No tabs available. Add at least two pages to the tab.</p>
      <p v-else-if="tabs.length === 1">Only one tab provided. Add another page for the tab to show.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useWindowSize } from '@vueuse/core';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  activeColor: {
    type: String,
    default: '#42b983',
  },
  inactiveColor: {
    type: String,
    default: '#c0c0c0',
  },
  excludeRoutes: {
    type: Array,
    default: () => [],
  },
});

const activeTab = ref(null);
const route = useRoute();

const setActiveTab = (tabName) => {
  activeTab.value = tabName;
};

const getIconStyle = (tabName) => {
  return {
    color: activeTab.value === tabName ? props.activeColor : props.inactiveColor,
  };
};

const getIconName = (tab) => {
  return activeTab.value === tab.name ? tab.activeIcon : tab.inactiveIcon;
};

const { width } = useWindowSize();
const isMobile = computed(() => width.value < 768);

const isExcludedRoute = computed(() => {
  return props.excludeRoutes.some(excludedRoute => route.path.includes(excludedRoute));
});

watch(route, () => {
  if (isExcludedRoute.value) {
    activeTab.value = null; // Reset active tab if the current route is excluded
  } else {
    const matchingTab = props.tabs.find(tab => tab.route === route.path);
    if (matchingTab) {
      activeTab.value = matchingTab.name;
    }
  }
});
</script>

<style scoped>
.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  

  
}
.bottom-nav ul {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin: 0;
  list-style: none;
}
.bottom-nav li {
  flex-grow: 1;
  text-align: center;
  padding: 10px 0;
  cursor: pointer;
  font-size: 14px;
}
.bottom-nav li.active {
  color: var(--active-color, #42b983);
}
.bottom-nav li i {
  display: block;
  font-size: 24px;
  margin-bottom: 5px;
}
.bottom-nav li a {
  color: inherit;
  text-decoration: none;
}
.no-tabs-message {
  padding: 10px;
  text-align: center;
  color: #999;
}

ion-icon {
  font-size: 26px;
}
</style>
