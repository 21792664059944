<template>
  <div class="timer-container">
    <div v-if="showTimer" class="countdown">
      <h3>Waiting for M-Pesa Payment</h3>
      <div class="timer">{{ timerCount }} seconds remaining</div>
    </div>

    <div v-if="paymentFailed" class="payment-failed">
      <h3>Payment Failed</h3>
      <p>{{ manualMessage }}</p>
    </div>

    <div v-if="status" class="payment-success">
      <h3>Payment Successful</h3>
      <p>Your transaction has been completed.</p>
    </div>
  </div>
</template>

<script setup>
import { ref, onUnmounted } from 'vue';

const props = defineProps({
  initialTime: {
    type: Number,
    default: 60
  }
});

const emit = defineEmits(['timer-complete', 'payment-success', 'payment-failed']);

const showTimer = ref(false);
const timerCount = ref(props.initialTime);
const status = ref(false);
const paymentFailed = ref(false);
const manualMessage = ref("");

let timer = null;

function startTimer() {
  showTimer.value = true;
  timerCount.value = props.initialTime;
  
  timer = setInterval(() => {
    timerCount.value--;
    if (timerCount.value <= 0) {
      stopTimer();
      if (!status.value) {
        setPaymentFailed();
      }
    }
  }, 1000);
}

function stopTimer() {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
  showTimer.value = false;
}

function setPaymentSuccess() {
  status.value = true;
  paymentFailed.value = false;
  stopTimer();
  emit('payment-success');
}

function setPaymentFailed(message = '') {
  status.value = false;
  paymentFailed.value = true;
  manualMessage.value = message;
  stopTimer();
  emit('payment-failed');
}

function reset() {
  stopTimer();
  status.value = false;
  paymentFailed.value = false;
  manualMessage.value = "";
  timerCount.value = props.initialTime;
}

// Expose methods to parent component
defineExpose({
  startTimer,
  stopTimer,
  setPaymentSuccess,
  setPaymentFailed,
  reset
});

// Cleanup on component unmount
onUnmounted(() => {
  stopTimer();
});
</script>