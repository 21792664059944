<template>
  <div class="flex justify-content-center align-items-center w-full">
    <div class="surface flex flex-column align-items-center text-center px-5 py-8">
      <i class="pi pi-inbox text-6xl text-600 mb-4"></i>
      
      <div class="text-900 font-medium text-xl mb-2">
        {{ message }}
      </div>
      
      <span class="text-600 mb-5" v-if="showSub">
        Add items or adjust your filters to find what you're looking for.
      </span>

      <router-link 
        v-if="route"
        :to="route ? `/${route}/add` : ''"
      >
        <Button 
          icon="pi pi-plus" 
          label="Create new"
          class="text-primary-400 bg-primary p-button-text w-[200px] text-600 border-round page-button m-2"
        />
      </router-link>
   
    </div>
  </div>
</template>

<script>
export default {
  name: 'Empty',
  props: {
    message: {
      type: String,
      default: 'No content available.'
    },
    route: {
            type: String,
            default: ''
    },
 showSub: {
            type: Boolean,
            default: true
 }


  }
}
</script>

<style scoped>
.empty {
  text-align: center;
  color: #999;
  padding: 20px;
}
</style>

