<template>
  <div class="payment-container">
    <div class="spinner-container">
      <ProgressSpinner />
      <img src="/images/logo.png" class="spinner-image" alt="Loading icon"/>
    </div>
   </div>
</template>

<style scoped>


.spinner-container {
  position: relative;
  display: inline-block;


}

.spinner-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 44px; /* Adjust size as needed */
  height: 44px; /* Adjust size as needed */
  z-index: 1;
}
</style>