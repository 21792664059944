<template>
  <div class="sm:container-fluid md:container p-6 bg-white shadow-md rounded-lg">
    <div class="flex align-items-center mb-4">
      <h2 class="text-2xl font-bold text-gray-800">{{ event.name }}</h2>
      <p class="m-0 ml-4 text-gray-600">Fill the form and wait for an Mpesa prompt</p>
    </div>
    <div class="grid p-2">
      <div class="form-group col-12 mb-4">
        <InputText
          v-model.trim="formData.fullName"
          label="Full Name"
          type="text"
          placeholder="Enter your full name"
          class="w-full p-inputtext-sm"
          :class="{ 'p-invalid': v$.fullName.$error }"
        />
        <small v-if="v$.fullName.$error" class="p-error">Full name is required</small>
      </div>

      <div class="form-group col-12 mb-4">
        <InputText
          v-model.trim="formData.phone"
          label="Phone Number"
          type="text"
          placeholder="Safaricom phone number"
          class="w-full p-inputtext-sm"
          :class="{ 'p-invalid': v$.phone.$error }"
        />
        <small v-if="v$.phone.$error" class="p-error">
          {{ v$.phone.$errors[0].$message }}
        </small>
      </div>

      <!-- Only show categories section if there are categories available -->
      <div v-if="event.categories && event.categories.length > 0" class="form-group col-12 mb-4">
        <span class="font-medium mb-2">Select Categories *</span>
        <div id="ctrl-categories-holder" class="flex flex-wrap">
          <div
            class="field-checkbox mr-4"
            v-for="option in spl(event.categories)"
            :key="option"
          >
            <Checkbox
              :id="option"
              name="ctrlcategories"
              :value="option"
              v-model="formData.categories"
            />
            <label :for="option" class="ml-2">{{ option }}</label>
          </div>
        </div>
        <small v-if="v$.categories.$error" class="p-error">At least one category must be selected</small>
      </div>

      <div class="w-full mb-4">
        <i class="pi pi-exclamation-triangle mr-2 text-yellow-600"></i>
        Leave empty if not playing doubles
      </div>

      <div class="w-full border-1 surface-border border-round mt-2 mb-4 p-3">
        <div class="form-group col-12 mb-4">
          <InputText
            v-model.trim="formData.xd_partner"
            label="Xd Partner"
            type="text"
            placeholder="Enter Xd partner's name"
            class="w-full p-inputtext-sm"
          />
        </div>
        <div class="form-group col-12 mb-4">
          <InputText
            v-model.trim="formData.md_partner"
            label="Md Partner"
            type="text"
            placeholder="Enter Md partner's name"
            class="w-full p-inputtext-sm"
          />
        </div>
      </div>
    </div>

    <div class="text-center my-4">
      <Button
        class="p-button-primary p-3 w-24rem"
        label="Submit Entry"
        @click="handleSubmit"
      />
    </div>

    <div v-if="message" class="text-red-500 text-center mt-2">{{ message }}</div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { ApiService } from 'src/services/api';

const props = defineProps({
  event: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['submit-form']);

const formData = ref({
  fullName: "",
  phone: "",
  md_partner: "",
  xd_partner: "",
  categories: []
});

const message = ref(""); // Reactive message variable for feedback

const phoneValidator = helpers.regex(/^0\d{9}$/);

const rules = computed(() => {
  const baseRules = {
    fullName: { required },
    phone: { 
      required,
      validPhone: helpers.withMessage(
        'Phone number must be 10 digits and start with 0',
        phoneValidator
      )
    },
    categories: {} // Initialize categories rule as empty
  };

  // Only enforce the required rule if categories are available
  if (props.event?.categories && props.event.categories.length > 0) {
    baseRules.categories.required = helpers.withMessage('At least one category must be selected', value => value.length > 0);
  }

  return baseRules;
});

// Ensure v$ is initialized correctly
const v$ = useVuelidate(rules, formData);

function spl(str) {
  if (!str) return [];
  return str.split(",");
}

async function handleSubmit() {
  v$.value.$touch();
  if (!v$.value.$invalid) {
    await postSubmission(props.event.id, props.event.fee, props.event.owner);
  }
}

async function postSubmission(id, fee, owner) {
  try {
    let postData = {
      owner: owner,
      amount: fee,
      phone_number: formData.value.phone,
      full_name: formData.value.fullName,
      event_id: id,
      md_partner: formData.value.md_partner,
      xd_partner: formData.value.xd_partner,
      categories: formData.value.categories.join(),
      channel: props.event.channel // Ensure channel is defined in your event object
    };
    let response = await ApiService.post("components_data/register", postData);
    if (response.data.success) {
      message.value = "Registration successful!"; // Set success message
    } else {
      message.value = "Registration failed. Please try again."; // Set failure message
      console.log(response);
    }
  } catch (e) {
    console.error(e);
    message.value = "An error occurred while submitting your entry."; // Set error message
  }
}
</script>

<style scoped>
/* Add any additional styles here if needed */
</style>
