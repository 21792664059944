<template>
  <div v-if="items" class="grid">
    <template v-for="(item, i) of items">
      <div v-if="visible(item) && !item.separator" :key="item.label || i"
           class="col-6 md:col-6 ">
        <div class="p-1 border-1 surface-border border-round">
          <template v-if="root">
            <div class="text-xl font-bold mb-3" :aria-label="item.label">{{item.label}}</div>
            <appsubmenumobile :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenumobile>
          </template>
          <template v-else>
            <router-link v-if="item.to" :to="item.to" 
                          :class="['flex align-items-center p-2 border-round cursor-pointer', item.class, {'p-disabled': item.disabled}]"
                         :style="item.style" @click="onMenuItemClick($event,item,i)" :target="item.target" 
                         :aria-label="item.label" exact role="menuitem" v-ripple>
            <ion-icon :name="item.icon" size="small" class="mr-2"></ion-icon>
              <span class="menu-label">{{item.label}}</span>
              <i v-if="item.items" class="pi pi-fw pi-angle-down menuitem-toggle-icon"></i>
              <Badge v-if="item.badge" :value="item.badge"></Badge>
            </router-link>
             <a v-if="!item.to" :href="item.url||'#'" :style="item.style"
               :class="['flex align-items-center p-2 border-round cursor-pointer', item.class, {'p-disabled': item.disabled}]"
               @click="onMenuItemClick($event,item,i)" :target="item.target"
               :aria-label="item.label" role="menuitem" v-ripple>
            <ion-icon :name="item.icon" size="small" class="mr-2"></ion-icon>
              <span class="flex-grow-1">{{item.label}}</span>
              <i v-if="item.items" class="pi pi-fw pi-angle-down ml-auto"></i>
              <Badge v-if="item.badge" :value="item.badge" class="ml-2"></Badge>
            </a>
            <OverlayPanel v-if="item.items" :ref="el => opRefs[i] = el" class="w-full" style="z-index: 999">
              <div class="col-12 w-full">
                <appsubmenumobile :items="item.items" :root="false" @menuitem-click="onSubItemClick" class="col-12"></appsubmenumobile>
              </div>
            </OverlayPanel>
          </template>
        </div>
      </div>
      
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import Badge from 'primevue/badge';

const opRefs = ref([]);

export default {
  name: 'appsubmenumobile',
  components: {
    OverlayPanel,
    Badge
  },
  props: {
    items: Array,
    root: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: null
    }
  },
  methods: {
    onMenuItemClick(event, item, index) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }
      
      if (!item.to && !item.url) {
        event.preventDefault();
      }

      if (item.command) {
        item.command({originalEvent: event, item: item});
      }

      if (item.items) {
        this.toggle(event, index);
      }

      this.activeIndex = index === this.activeIndex ? null : index;

      this.$emit('menuitem-click', {
        originalEvent: event,
        item: item
      });
    },
    onSubItemClick(event) {
      this.$emit('menuitem-click', event);
    },
    visible(item) {
      return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
    },
    toggle(event, index) {
      opRefs.value[index].toggle(event);
    }
  },
  setup() {
    return {
      opRefs
    }
  }
}
</script>